import request from '../plugins/axios'

export function Hot() {
  return request({
    url: '/api/Swap/Hot',
    method: 'POST'
  })
}

export function Search(params) {
  return request({
    url: '/api/Swap/Search',
    method: 'GET',
    params
  })
}

export function Pool(params) {
  return request({
    url: '/api/Swap/Pool',
    method: 'GET',
    params
  })
}

export function Transactions(params) {
  return request({
    url: '/api/Swap/Transactions',
    method: 'GET',
    params
  })
}

export function PoolTransactions(params) {
  return request({
    url: '/api/Swap/PoolTransactions',
    method: 'GET',
    params
  })
}

export function TokenKLine(params) {
  return request({
    url: '/api/Swap/TokenKLine',
    method: 'GET',
    params
  })
}

export function Tokens(params) {
  return request({
    url: '/api/Swap/Tokens',
    method: 'GET',
    params
  })
}

export function TvlK() {
  return request({
    url: '/api/Swap/TvlK',
    method: 'GET'
  })
}

export function VolumeK(params) {
  return request({
    url: '/api/Swap/VolumeK',
    method: 'GET',
    params
  })
}

export function TokenInfo(params) {
  return request({
    url: '/api/Swap/TokenInfo',
    method: 'GET',
    params
  })
}

export function PairInfo(params) {
  return request({
    url: '/api/Swap/PairInfo',
    method: 'GET',
    params
  })
}
export function PairKLine(params) {
  return request({
    url: '/api/Swap/PairKLine',
    method: 'GET',
    params
  })
}

// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { Pool } from '../../api/swap'

import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

interface styleProps {
  w?: string // 可选的宽度属性
  minW?: string
  align?: string
  color?: string
}

// 定义头部样式
const Head = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  overflow: auto;
  background: #f9f9f9;
  border-radius: 20px 20px 0 0;
  min-height: 52px;
  padding: 12px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-bottom: 0;
  color: #7d7d7d;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Span = styled.span<styleProps>`
  font-size: 16px;
  font-weight: 485;
  line-height: 24px;
  min-width: ${props => props.minW || '133px'};
  padding: 0 8px;
  width: ${props => props.w || 'auto'};
  text-align: ${props => props.align || 'end'};
  color: ${props => props.color || ''};
  & > span {
    color: #7d7d7d;
  }
  height: 100%;
  display: flex;
  align-items: center;
  .link {
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: #7d7d7d;
    }
  }
`

const Body = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background: #fff;
  border-radius: 0 0 20px 20px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Item = styled.div`
  width: 100%;
  cursor: pointer;
  /* overflow: hidden; */
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 12px;
  color: #333;

  &:hover {
    background: #f4f4f4;
    span {
      background: #f4f4f4;
    }
  }
`

export default function Pools({ address }) {
  const history = useHistory()
  function jump(token) {
    history.push('/explore/pools/' + token)
  }
  const arr = [...Array(100).keys()]
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await Pool({ wd: address ? address : '' })
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [address])

  const headRef = useRef(null)
  const bodyRef = useRef(null)

  // 同步滚动
  const handleScroll = (source, target) => {
    target.current.scrollLeft = source.current.scrollLeft
  }
  return (
    <>
      <Head ref={headRef} onScroll={() => handleScroll(headRef, bodyRef)}>
        <Span minW="78px" align="center">
          #
        </Span>
        <Span w="500px" align="left">
          Pool
        </Span>
        <Span minW="200px">TVL</Span>
        {/* <Span minW="200px">APR</Span> */}
        <Span minW="200px">1D vol</Span>
        <Span minW="200px">30D vol</Span>
        <Span minW="200px">1D vol/TVL</Span>
      </Head>
      <Body ref={bodyRef} onScroll={() => handleScroll(bodyRef, headRef)}>
        {list.map((item, index) => {
          return (
            <Item key={index} onClick={() => jump(item.pair)}>
              <Span minW="78px" align="center">
                {index + 1}
              </Span>

              <Span w="500px" align="left">
                {item.symbol0}/{item.symbol1}
              </Span>
              <Span minW="200px">${item.tvl}</Span>
              {/* <Span minW="200px">{item.dayVol}%</Span> */}
              <Span minW="200px">${item.dayVol}</Span>
              <Span minW="200px">${item.day30Vol}</Span>
              <Span minW="200px">{item.volTvlRatio24h}</Span>
            </Item>
          )
        })}
      </Body>
    </>
  )
}

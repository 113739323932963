// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Tokens as getToken } from '../../api/swap'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import formatLongNumber from '../../utils/formatLongNumber'
import topIcon from '../../assets/svg/top.svg'
import bottomIcon from '../../assets/svg/bottom.svg'

interface styleProps {
  w?: string // 可选的宽度属性
  minW?: string
  align?: string
  color?: string
}

// 定义头部样式
const Head = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  overflow: auto;
  background: #f9f9f9;
  border-radius: 20px 20px 0 0;
  min-height: 52px;
  padding: 12px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-bottom: 0;
  color: #7d7d7d;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Span = styled.span<styleProps>`
  font-size: 16px;
  font-weight: 485;
  line-height: 24px;
  min-width: ${props => props.minW || '133px'};
  padding: 0 8px;
  width: ${props => props.w || 'auto'};
  text-align: ${props => props.align || 'end'};
  color: ${props => props.color || ''};
  height: 100%;
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
    color: #7d7d7d;
  }
`

const Body = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background: #fff;
  border-radius: 0 0 20px 20px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Item = styled.div`
  width: 100%;
  cursor: pointer;
  /* overflow: hidden; */
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 12px;
  color: #333;

  &:hover {
    background: #f4f4f4;
    span {
      background: #f4f4f4;
    }
  }
`

export default function Tokens() {
  const history = useHistory()

  const headRef = useRef(null)
  const bodyRef = useRef(null)

  // 同步滚动
  const handleScroll = (source, target) => {
    target.current.scrollLeft = source.current.scrollLeft
  }

  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getToken()
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  function jump(token) {
    history.push('/explore/tokens/' + token)
  }
  return (
    <>
      <div style={{ overflow: 'auto' }}>
        <Head ref={headRef} onScroll={() => handleScroll(headRef, bodyRef)}>
          <Span minW="44px" align="center">
            #
          </Span>
          <Span w="240px" minW={isMobile ? '250px' : '44px'} align="left">
            Token name
          </Span>
          <Span minW={isMobile ? '150px' : '180px'}>Price</Span>
          <Span minW={isMobile ? '150px' : '180px'}>1 hour</Span>
          <Span minW={isMobile ? '150px' : '180px'}>1 day</Span>
          {/* <Span>FDV</Span> */}
          <Span minW={isMobile ? '150px' : '180px'}>↓ Volume</Span>
          <Span></Span>
        </Head>
        <Body ref={bodyRef} onScroll={() => handleScroll(bodyRef, headRef)}>
          {list.map((item, index) => {
            return (
              <Item key={index} onClick={() => jump(item.contract)}>
                <Span minW="44px" align="center">
                  {index + 1}
                </Span>

                <Span w={isMobile ? '300px' : '240px'} minW={isMobile ? '250px' : '44px'} align="left">
                  {item.name}
                  <span>{item.symbol}</span>
                </Span>
                <Span minW={isMobile ? '150px' : '180px'}>${item?.price}</Span>
                <Span
                  minW={isMobile ? '150px' : '180px'}
                  color={item['h1'] < 0 ? 'rgb(255, 95, 82)' : 'rgb(64, 182, 107)'}
                >
                  <img src={item['h1'] < 0 ? bottomIcon : topIcon}  />
                  {formatLongNumber(item['h1'] * 100)}%
                </Span>
                <Span
                  minW={isMobile ? '150px' : '180px'}
                  color={item['d1'] < 0 ? 'rgb(255, 95, 82)' : 'rgb(64, 182, 107)'}
                >
                  <img src={item['h1'] < 0 ? bottomIcon : topIcon}  />
                  {formatLongNumber(item['d1'] * 100)}%
                </Span>
                {/* <Span>0.00</Span> */}
                <Span minW={isMobile ? '150px' : '180px'}>${item.volume}</Span>
                <Span>
                  <img src={`/kl/${item.contract}_1d.svg`} alt="" />
                </Span>
              </Item>
            )
          })}
        </Body>
      </div>
    </>
  )
}

// @ts-nocheck

import styled from 'styled-components'
import Tokens from './Tokens'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Swap from './Swap'
import LeftChart from './LeftChart'
import { Search, PairInfo } from '../../api/swap'
import { isMobile } from 'react-device-detect'
import { useHistory, useParams } from 'react-router-dom'
import Transactions from './PoolsTransactions'
import PoolsChart from './PoolsChart'
import subAddress from '../../utils/subAddress'

const Page = styled.div`
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    &:first-child {
      width: 65%;
    }
    &:last-child {
      flex-shrink: 0;
      /* margin-left: 100px; */
      width: 350px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    span {
      margin-right: 3px;
      cursor: pointer;
    }
  }
  .title {
    margin-top: 20px;
    font-size: 24px;
    color: #222;
    font-weight: 500;
    span {
      padding-left: 10px;
      color: #7d7d7d;
    }
  }
  .status {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: ; */
    div {
      span {
        display: block;
        &:first-child {
          font-size: 14px;
          color: rgb(125, 125, 125);
        }
        &:last-child {
          margin-top: 4px;
          font-size: 28px;
          color: rgb(34, 34, 34);
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 16px;
    width: 100%;
    .status {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
    & > div {
      width: 100% !important;
      &:last-child {
        display: nnone;
      }
    }
  }
`

export default function EthrerumToken() {
  const history = useHistory()
  const { token } = useParams<{ token?: any }>()
  const [tokenData, setTokenData] = useState({})

  function jump() {
    history.push('/explore')
  }

  async function searchData() {
    // let result = await Search({ wd: token })
    let { data: tokenData } = await PairInfo({ pair: token })
    console.log('🚀  searchData  tokenData:', tokenData.stats.tokenA.symbol)
    setTokenData(tokenData.stats)
  }
  let [chartData, setChartData] = useState(0)
  function getChartData(data: any) {
    setChartData(data)
  }

  useEffect(() => {
    searchData()
  }, [])
  return (
    <Page>
      <div>
        <div className="nav" onClick={jump}>
          <span>Explore &gt;</span>
          <span>Pools &gt;</span>
          <span>
            {tokenData?.tokenA?.name}/ {tokenData?.tokenB?.name} {subAddress(token)}
          </span>
        </div>
        <div className="title">
          {tokenData?.tokenA?.symbol}/ {tokenData?.tokenB?.symbol}
          {/* <span>{tokenData?.symbol}</span> */}
        </div>
        <div className="title">${chartData}</div>
        <PoolsChart token={token} onData={getChartData}></PoolsChart>
        {/* <LeftChart width={isMobile ? 320 : 780} height={isMobile ? 250 : 400}></LeftChart> */}

        <div className="status">
          <div>
            <span>TVL</span>
            <span>${tokenData?.tvl || '0.00'}</span>
          </div>

          <div>
            <span>24H volume</span>
            <span>${tokenData?.volume || '0.00'}</span>
          </div>
          {/* <div>
            <span>24H fees</span>
            <span></span>
          </div> */}
        </div>
        <Transactions
          address={token}
          symbol0={tokenData?.tokenA?.symbol || ''}
          symbol1={tokenData?.tokenB?.symbol || ''}
        ></Transactions>
      </div>
      <div>{!isMobile && <Swap></Swap>}</div>
    </Page>
  )
}

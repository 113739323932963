import axios from "axios";

const service = axios.create({
  // baseURL:'https://devswap.tokchain.org',
  baseURL:'/',
  timeout: 600 * 1000 // 设置请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  async config => {

    let { params, data, method, url } = config;
    // config.headers["authorization"] = 'Bearer ' + localStorage.getItem("token");
    if (method === "GET") {
      params && (config.params = params);
    } else {
      data && (config.data = data);
    }
    return config;
  },
  error => {
    // 发送失败
    console.log("请求错误", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;

    if (res.error) return Promise.reject(res);

    return res;
  },
  error => {
    return Promise.reject(error.response);
  }
);

export default service;
// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import LeftChart from './LeftChart'
import RightChat from './RightChat'

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  .box {
    width: 50%;
    .title {
      color: #7d7d7d;
    }

    .data {
      margin-top: 20px;
      color: '#000';
      font-size: 30px;
      font-weight: 500;
    }

    .img {
      width: 100%;
      height: 400px;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    .box {
      width: 48%;
      height: 110px;
      background: #f9f9f9;
      border-radius: 20%;
      padding: 20px;
      .title {
        font-size: 12px;
      }
      .data {
      }
    }
    .img {
      display: none;
    }
  }
`

export default function Line() {
  const [leftData, setData] = useState(0)
  const [rightData, setRightData] = useState(0)

  function getData(data: any) {
    // console.log(data);
    setData(data)
  }

  function getRightData(data: any) {
    // console.log(data);
    setRightData(data)
  }

  return (
    <Wrap>
      <div className="box">
        <div className="title">Tokswap TVL</div>
        <div className="data">${leftData}</div>
        <div className="img">
          <LeftChart width={530} height={340} onData={getData}></LeftChart>
        </div>
      </div>
      <div className="box">
        <div className="title">Tokswap volume</div>
        <div className="data">${rightData}</div>
        <div className="img">
          <RightChat height={340} onData={getRightData}></RightChat>
        </div>
      </div>
    </Wrap>
  )
}

// @ts-nocheck
import { VolumeK } from '../../api/swap'
import React, { useState, useRef, useEffect } from 'react'
import * as echarts from 'echarts'

export default function RightChat({ height, onData }) {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null)
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current)
    }
    // 清理图表实例
    return () => {
      chartInstance.current && chartInstance.current.dispose()
    }
  }, [])

  async function getData() {
    let { data } = await VolumeK({ t: '1d' })
    setData([...data])
  }

  useEffect(() => {
    if (!chartInstance.current || data.length === 0) return

    const time = data.map(item => new Date(item.timestamp * 1000).toLocaleString())
    const price = data.map(item => item.volumeU)
    const totalVolume = (data.reduce((sum, item) => sum + item.volumeU, 0)).toFixed(2)
    onData && onData(totalVolume)

    const option = {
      xAxis: {
        type: 'category',
        boundaryGap: false, // 关闭两端的留白
        data: time,
        axisLabel: {
          interval: 35,
          show: false
        },
        splitLine: {
          show: false // 隐藏 x 轴的网格线
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false
        },
        splitLine: {
          show: false // 隐藏 x 轴的网格线
        }
      },
      tooltip: {
        trigger: 'axis',
        // formatter: params => {
        //   onData && onData(params[0].data)
        // }
      },
      grid: {
        left: '0%',
        top: '10%',
        right: '5%',
        bottom: '0%',
        containLabel: true,
        show: false
      },
      series: [
        {
          data: price,
          type: 'bar',
          emphasis: {
            focus: 'series',
            itemStyle: {}
          },
          z: 10,
          itemStyle: {
            normal: {
              color: '#fc72ff'
            }
          }
          // itemStyle: {
          //   borderColor: 'transparent', // 设置透明边界
          //   borderWidth: 20 // 扩展边界区域
          // }
        }
      ]
    }

    chartInstance.current.setOption(option)

    chartInstance.current.on('mouseover', params => {
      // console.log('🚀  useEffect  params:', params)

      if (params.componentType === 'series') {
        // console.log('Mouse entered data:', {
        //   name: params.name, // x 轴值
        //   value: params.value, // y 轴值
        //   seriesName: params.seriesName // 系列名称
        // })
        onData && onData(Number(params.value).toFixed(2))
        // 可以在这里处理你的逻辑，例如更新其他组件、显示额外信息等
      }
    })

    chartInstance.current.on('mouseout', params => {
      if (params.componentType === 'series') {
        onData && onData(totalVolume)
      }
    })
  }, [data])

  return <div ref={chartRef} style={{ width: '100%', height: height + 'px' }}></div>
}

// @ts-nocheck

import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { isMobile } from 'react-device-detect'

const DetailChart = ({ data }: { data: any[] }) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null)

  useEffect(() => {
    if (!chartRef.current) return

    chartInstance.current = echarts.init(chartRef.current)

    return () => {
      chartInstance.current?.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chartInstance.current) return

    const time = data.map(item => new Date(item.timestamp * 1000).toLocaleString())
    const price = data.map(item => item.openPrice)

    const calculatePercentageChangeWithTrend = data => {
      if (data.length === 0) return []

      const firstPrice = data[0].openPrice

      return data.map(item => {
        const percentageChange = ((item.openPrice - firstPrice) / firstPrice) * 100
        const trend = item.openPrice > firstPrice ? 'up' : item.openPrice < firstPrice ? 'down' : 'flat'
        return {
          ...item,
          percentageChange: percentageChange.toFixed(2) + '%',
          trend
        }
      })
    }
    let a = calculatePercentageChangeWithTrend(data)
    console.log(a)

    const option = {
      xAxis: {
        type: 'category',
        boundaryGap: false, // 关闭两端的留白
        data: time,
        axisLabel: {
          interval: 35,
          show: !isMobile
        }
      },
      yAxis: {
        type: 'value'
      },
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '0%',
        top: '10%',
        right: '5%',
        bottom: '2%',
        containLabel: true
      },
      series: [
        {
          data: price,
          type: 'line'
        },
      ]
    }
    chartInstance.current.setOption(option)
    chartInstance.current.on('mouseover', params => {
      console.log(params)
      // if (params.componentType === 'series') {
      // }
    })
  }, [data])

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
}

export default DetailChart

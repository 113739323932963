export default function maskString(string, num = 3) {
  let str = String(string);
  if (str.length <= num * 2) {
    return str;
  }

  const front = str.slice(0, num);
  const back = str.slice(-num);

  const maskedPart = "...";

  return front + maskedPart + back;
}

// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react'
import { Transactions as getTransactions, PoolTransactions } from '../../api/swap'
import styled from 'styled-components'
import subAddress from '../../utils/subAddress'
import multiply from '../../utils/multiply'
import formatLongNumber from '../../utils/formatLongNumber'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
let BROWSER = process.env.REACT_APP_BROWSER

interface styleProps {
  w?: string // 可选的宽度属性
  minW?: string
  align?: string
  color?: string
}

// 定义头部样式
const Head = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  overflow: auto;
  background: #f9f9f9;
  border-radius: 20px 20px 0 0;
  min-height: 52px;
  padding: 12px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-bottom: 0;
  color: #7d7d7d;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Span = styled.span<styleProps>`
  font-size: 16px;
  font-weight: 485;
  line-height: 24px;
  min-width: ${props => props.minW || '133px'};
  padding: 0 8px;
  width: ${props => props.w || 'auto'};
  text-align: ${props => props.align || 'end'};
  color: ${props => props.color || ''};
  & > span {
    color: #7d7d7d;
  }
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .link {
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: #7d7d7d;
    }
  }
`

const Body = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid rgba(34, 34, 34, 0.05);
  background: #fff;
  border-radius: 0 0 20px 20px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const Item = styled.div`
  width: 100%;

  /* overflow: hidden; */
  display: flex;
  height: 64px;
  align-items: center;
  padding: 0 12px;
  color: #333;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
    span {
      background: #f4f4f4;
    }
  }
`

export default function Transactions({ address, symbol0 = '', symbol1 = '' }) {
  const arr = [...Array(100).keys()]
  const [list, setList] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await PoolTransactions({ Pair: address })
        setList(data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [address])

  const headRef = useRef(null)
  const bodyRef = useRef(null)

  // 同步滚动
  const handleScroll = (source, target) => {
    target.current.scrollLeft = source.current.scrollLeft
  }

  function isMilliseconds(timestamp: any) {
    if (typeof timestamp !== 'string' && typeof timestamp !== 'number') {
      throw new TypeError('Parameter must be a string or a number')
    }
    return timestamp.toString().length === 13
  }

  const handleTimeAgo = (time1: any, time2 = moment(), flag: any) => {
    // Ensure time1 and time2 are in milliseconds
    time1 = isMilliseconds(time1) ? time1 : time1 * 1000
    time2 = isMilliseconds(time2.valueOf()) ? time2.valueOf() : time2.valueOf() * 1000

    // Calculate the duration between time1 and time2
    const duration = moment.duration(moment(time2).diff(moment(time1)))

    // Get total days
    const totalDays = duration.asDays() // Get the total difference in days

    // Define time units
    const timeUnits = [
      { unit: 'day', value: Math.floor(totalDays) }, // Use integer number of days
      { unit: 'hr', value: duration.hours() },
      { unit: 'min', value: duration.minutes() },
      { unit: 'sec', value: duration.seconds() }
    ]

    // Iterate through the units to get the most significant one
    for (const { unit, value } of timeUnits) {
      if (value > 0) {
        return `${value} ${unit}${value > 1 ? 's' : ''} ${flag ? '' : 'ago'}`
      }
    }

    return '1 sec' // Fallback for very short time intervals
  }

  const history = useHistory()
  function jump(token) {
    history.push('/explore/tokens/' + token)
  }

  function jumpBrowser(address) {
    window.open(BROWSER + '/tx/' + address)
  }

  function jumpAddressBrowser(address) {
    window.open(BROWSER + '/address/' + address)
  }

  return (
    <>
      <Head ref={headRef} onScroll={() => handleScroll(headRef, bodyRef)}>
        <Span w="50px" align="left">
          Time
        </Span>
        <Span w="300px" minW={isMobile ? '250px' : ''} align="left">
          Type
        </Span>
        <Span w="130px">USD</Span>
        <Span w="200px">{symbol0}</Span>
        <Span w="200px">{symbol1}</Span>
        <Span w="220px">Wallet</Span>
      </Head>
      <Body ref={bodyRef} onScroll={() => handleScroll(bodyRef, headRef)}>
        {list.map((item, index) => {
          return (
            <Item
              key={index}
              onClick={() => {
                jumpBrowser(item.transactionHash)
              }}
            >
              <Span w="50px" align="left">
                <span>{handleTimeAgo(item.ts)}</span>
              </Span>
              <Span w="300px" minW={isMobile ? '250px' : ''} align="left">
                <span
                  style={{
                    color: item.type === 'buy' || item.type === 'add' ? 'rgb(64, 182, 107)' : 'rgb(255, 95, 82)'
                  }}
                >
                  {item.type} {item.type === 'buy' || item.type === 'sell' ? item.symbol0 : ''}
                </span>
              </Span>
              <Span w="130px">${multiply(formatLongNumber(item.usd, 4))}</Span>
              <Span w="200px">{multiply(formatLongNumber(item?.amount0, 4))} </Span>
              <Span w="200px">{multiply(formatLongNumber(item?.amount1, 4))} </Span>
              <Span
                w="220px"
                onClick={e => {
                  e.stopPropagation()
                  jumpAddressBrowser(item.from)
                }}
              >
                {subAddress(item?.from, 5)}
              </Span>
            </Item>
          )
        })}
      </Body>
    </>
  )
}

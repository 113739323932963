// @ts-nocheck

import React, { useState } from 'react'
import styled from 'styled-components'
import Line from './Line'
import Tokens from './Tokens'
import Pools from './Pools'
import Transactions from './Transactions'
import { isMobile } from 'react-device-detect'

const TabContent = styled.div<{ isVisible: boolean }>`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  transition: opacity 0.3s ease-in-out; /* 添加平滑过渡效果 */
  opacity: ${props => (props.isVisible ? 1 : 0)};
`
const TabContainer = styled.span`
  &.active {
    font-weight: bold;
    color: #007bff;
  }
`
const Tab = styled.div`
  margin-top: 40px;
  display: flex;
  span {
    color: rgb(125, 125, 125);
    cursor: pointer;
    font-size: 24px;
    margin-right: 20px;
    font-weight: 500;
    &:hover {
      opacity: 0.6;
    }
  }
  .active {
    color: #000;
  }
`
const Page = styled.div`
  width: 1200px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px;
  }
`

export default function Explore() {
  const [current, setCurrent] = useState(0)
  const tab = [
    {
      id: 0,
      text: 'Tokens'
    },
    {
      id: 1,
      text: 'Pools'
    },
    {
      id: 2,
      text: 'Transactions'
    }
  ]
  return (
    <Page>
      <Line></Line>
      <Tab>
        {tab.map(item => (
          <span key={item.id} className={`${item.id === current ? 'active' : ''}`} onClick={() => setCurrent(item.id)}>
            {item.text}
          </span>
        ))}
      </Tab>
      <div className="right"></div>

      <TabContent isVisible={current === 0}>
        <Tokens />
      </TabContent>
      <TabContent isVisible={current === 1}>
        <Pools />
      </TabContent>
      <TabContent isVisible={current === 2}>
        <Transactions />
      </TabContent>
    </Page>
  )
}

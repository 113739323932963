import styled from 'styled-components'
interface styleProps {
  status?: string // 可选的宽度属性
  forbidden?: Boolean
}
interface styleProps {
  status?: string // 可选的宽度属性
  forbidden?: Boolean
}

export const CrossWrap = styled.div`
  width: 400px;
  margin: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const Currency = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .symbol {
    display: flex;
    align-items: center;
    img {
      width: 61px;
      height: 61px;
      border-radius: 50%;
    }
    div {
      margin-left: 16px;
      span {
        display: block;
        &:first-child {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
        &:last-child {
          margin-top: 12px;
          font-weight: 600;
          font-size: 20px;
          opacity: 0.6;
        }
      }
    }
  }
  .change {
    width: 56px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
`
export const InputWrap = styled.div`
  margin-top: 32px;
  background: #ebebeb;
  border-radius: 20px 20px 20px 20px;
  padding: 37px 27px;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 16px;
      color: #3d3d3d;
      span {
        font-weight: 600;
      }
    }
    button {
      width: 58px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ea6a5a;
      border-radius: 13px 13px 13px 13px;
      border: none;
      cursor: pointer;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
    }
  }
  .inputBox {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    div {
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #000000;
      img {
        width: 33px;
        height: 33px;
        margin-right: 6px;
      }
    }
    input {
      /* flex-grow: 1; */
      width: 60%;
      margin-right: 1px;
      border: none;
      height: 100%;
      outline: none;
      border: none;
      font-weight: 600;
      font-size: 18px;
      text-align: end;
      &:focus {
        border: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
  }
  .amount {
    margin-top: 12px;
    color: #000000;
    opacity: 0.7;
    font-size: 15px;
    font-weight: 400;
    text-align: end;
    margin-right: 10px;
  }
`

export const InfoData = styled.div`
  margin-top: 12px;
  background: #f0f3ff;
  border-radius: 20px 20px 20px 20px;
  padding: 37px 27px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 00;
    }
    span {
      &:first-child {
        font-weight: 600;
        color: #050505;
        font-size: 15px;
      }
      &:last-child {
        font-size: 15px;
        font-weight: 400;
        color: #3d3d3d;
      }
    }
  }
`
export const Button = styled.button<styleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  display: block;
  width: 100%;
  height: 62px;
  background: ${props => (props.status === 'Recharge' ? '#3f65ff' : '#E8332A')};
  border-radius: 31px 31px 31px 31px;
  font-size: 18px;
  line-height: 62px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  opacity: ${props => (props.forbidden ? '0.3' : '1')};
  img {
    vertical-align: sub;
    margin-right: 10px;
  }
`

export const CurrentAddress = styled.div`
  margin-top: 25px;
  text-align: center;
  font-weight: 600;
`
